































































import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import CourseDetailMixins from './mixins'
import {accMul} from '@/utils/utils'
import { checkNeedJoinGroup ,submitForm } from '@/modules/course/api/index'
import infoForm from '@/modules/common/components/info-form/index.vue'
interface AinfoForm{
  //id是只读的
  $refs: any
  check: any
}

  @Component({
    components: {
      infoForm
    }
    
  })
export default class CourseAfter extends Mixins(CourseDetailMixins) {
  collection: boolean = false
  formConfig:any = {}
  submiting:boolean = false
  mounted() {
    this.activeTab = 'classList'
    this.getGroupTask()
  }

  get processingPrecent() {
    let studyProgress = this.courseDetail.study_progress || 0
    let precent = accMul(studyProgress, 100)
    return precent
  }
  get briefRouteName() {
    let routeName = ''
    let type = this.$route.params.type
    if (type === 'community') {
      routeName = 'CommunityDetail'
    } else if (type === 'card') {
      routeName = 'Bricard'
    } else {
      routeName = 'Bri' + type
    }
    return routeName
  }
  doSubmitForm() {
    
    let AinfoForm:any = this.$refs.infoForm
    
    let infoFormPass = AinfoForm.check()
    
    if (infoFormPass) {
      
      let info = this.formConfig
      let {id} = this.$route.params
      let type = this.$route.name

      let {formVal} = AinfoForm
      
      let formData = []
      for (let i in formVal) {
        let param = {
          'key': i,
          'value': formVal[i]
        }
        formData.push(param)
      }
      
      if (this.submiting) return
      this.submiting = true
      let infoId :any = this.$refs.infoForm
      submitForm({
        form_id: infoId.info.id,
        form_data: formData,
        content_id: id,
        content_type: type
      }).then(({data: res}) => {
        this.submiting = false
        this.$message({
          message: '提交成功',
          type: 'success'
        });
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      }).catch(({errorObj}) => {
        this.submiting = false
        if (errorObj) {
          this.$message.error(errorObj.toastMsg)
        } else {
          this.$message.error('提交失败')
        }
      })
    }
  }
  getGroupTask() {
    this.$nextTick(() => {
      let contentType = this.courseDetail.type
      let contentId = this.courseDetail.content_id
      checkNeedJoinGroup({
        contentType,
        contentId
      }).then(res => {
        let {data} = res
        if (res.type === 'data_collection') {
          this.collection = true
        } else {
          this.$emit('success')
        }
      }).catch(({errorObj}) => {
        // errorObj && this.toast(errorObj.toastMsg)
        // this.$emit('error', errorObj)
      })
    })
  }
}
