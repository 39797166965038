












import { Component, Vue, Watch, Prop,Mixins } from 'vue-property-decorator'
import fromGroup from './form-group.vue'
import * as utils from './filters/utils'
import TextMixins from './mixins'
  @Component({
    components: {
      fromGroup
    }
  })
export default class MyComponent extends Mixins(TextMixins) {
  // @Prop({ default: () => { return {} } }) iptData! :any
  maxLength:number = 100
  TextInput: any = ''
  get pass() {
    // 必填验证
    // 最多 100 个数字
    let flag = true
    let validation = this.iptData.validation
    let reg = validation.regex ? new RegExp(validation.regex) : /^\d+$/
    if (!this.requireValid) {
      flag = false
    } else if (typeof this.val !== 'undefined' && this.val !== '') {
      let num = parseFloat(this.val)

      if (validation.max && num > validation.max) {
        // 超
        this.errMsg = this.iptData.name + ' 不可大于' + validation.max + '，数字支持输入' + validation.min + '-' + validation.max + '，不支持输入小数点'
        flag = false
      } else if (validation.min && num < validation.min) {
        // 小
        this.errMsg = this.iptData.name + ' 不可小于' + validation.min + '，数字支持输入' + validation.min + '-' + validation.max + '，不支持输入小数点'
        flag = false
      } else if (!reg.test(this.val)) {
        let tips = this.iptData.name + '格式错误'
        this.errMsg = tips
        flag = false
      }
    }
    return flag
  }
  inputHandler() {
    // let ipt:any = this.$refs.ipt
    this.updateVal(this.TextInput)
  }
  getStrBytes(str:any) {
    if (str == null || str === undefined) return 0
    if (typeof str !== 'string') {
      return 0
    }
    let total = 0
    let charCode
    let i
    let len
    for (i = 0, len = str.length; i < len; i++) {
      charCode = str.charCodeAt(i)
      if (charCode <= 0x007f) {
        total += 1 // 字符代码在000000 – 00007F之间的，用一个字节编码
      } else {
        total += 2
      }
    }
    return total
  }
  mounted() {
    
  }
}
