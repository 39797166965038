// import area from '@/v-pages/checkout/components/info-form/components/area.js'
// import {OSSHost} from '@/config/setting'
// import moment from 'moment'
// import { formatTime as ft } from '@/filters/formatTime.js'

/**
 * 格式化时间
 *
 * @param {String} str
 * @returns 格式化后的时间
 */

/* eslint-disable */
// export const formatTime = ft

// export const formatTime = (date, transform) => {
//   if (!date) {
//     return '';
//   } else if (typeof date == 'number') {
//     date = new Date(date);
//   } else if (isNaN(Date.parse(date))) {
//     date = new Date(Date.parse(date.replace(/-/g, "/")));
//   } else {
//     date = new Date(Date.parse(date));
//   }
//   if (transform == 'timestamp') {
//     return Date.parse(date);
//   }
//   transform = transform || 'yyyy-MM-dd HH:mm';
//
//   const mon = date.getMonth() + 1,
//     dd = date.getDate(),
//     hh = date.getHours(),
//     mm = date.getMinutes(),
//     ss = date.getSeconds();
//
//   const year = date.getFullYear();
//   transform = transform.replace('yyyy', year)
//     .replace('MM', operateTime(mon)).replace('dd', operateTime(dd))
//     .replace('HH', operateTime(hh)).replace('mm', operateTime(mm))
//     .replace('hh', operateTime(hh)).replace('mm', operateTime(mm))
//     .replace('ss', operateTime(ss))
//   return transform;
// };



export const formatCountTime = (milliseconds:any) => {
  let diff = milliseconds;

  const UNITS = {
		'年': 31557600000,
		'月': 2629800000,
		'天': 86400000,
		'小时': 3600000,
		'分钟': 60000,
		'秒': 1000
	}

  let day = 0;
  let hour = 0;
  let min = 0;
  let sec = 0;
  let msec = 0;

  if (diff >= UNITS['天']) {
     day = Math.floor(diff / UNITS['天']);
     diff -= (day * UNITS['天']);
  }
  if (diff >= UNITS['小时']) {
    hour = Math.floor(diff / UNITS['小时']);
    diff -= (hour * UNITS['小时']);
  }
  if (diff >= UNITS['分钟']) {
    min = Math.floor(diff / UNITS['分钟']);
    diff -= (min * UNITS['分钟']);
  }
  if (diff >= UNITS['秒']) {
    sec = Math.floor(diff / UNITS['秒']);
    diff -= (sec * UNITS['秒']);
  }
  msec = diff;

  var _format = function (number:any) {
    return (number < 10 ? ('0' + number) : number);
  };

  if (milliseconds > 0) {

    var time = '';
    time += day ? _format(day) + '天' : '';
    time += _format(hour) + ':';
    time += _format(min) + ':';
    time += _format(sec);
    return time;
    // return _format(day) + '天' + _format(hour) + ':' + _format(min) + ':' + _format(sec) + ':' + _format(msec);
  } else{
    return '00:00:00';
  }
}

/**
 * 获取 shopID
 * 历史是 取 pathname 的第一级
 * http://www.duanshu.com/abc/lise/fea
 * pathname /{shopID}/lise/fea
 * 新需求改为 用当域名调接口取 shopId (main.js 启动应用前调接口)
 * 这里为了兼容 默认用历史方式取 shopId
 */
// let {pathname} = window.location
// let shopId = pathname.replace(/[\w-]*(\w+).html/, '').split('/')[1] || ''
// export const setShopId = (e) => {
//   shopId = e
// }
// export const getShopId = (e) => {
//   return shopId
// }

// export const getEnv = () => {
//   var env = getUrlParam('env')
//   if (!env) {
//     var host = location.host
//     var hostArr = host.split('.')
//     var topHost = hostArr[0]
//     var secHost = hostArr[1]
//     var env = 'production'

//     if (topHost.indexOf('test-') !== -1) {
//       env = 'develop'
//     } else if (topHost.indexOf('pre-') !== -1) {
//       env = 'prerelease'
//     }
//     if (secHost === 'test') {
//       env = 'develop'
//     } else if (secHost === 'pre') {
//       env = 'prerelease'
//     }
//   }
//   return env
// }

// 秒转时:分:秒
// export const formatSeconds = time => {
//   const formatBit = val => {
//     val = +val;
//     return val > 9 ? val : '0' + val; // 补0
//   }
//   const hour = Math.floor(time / 3600);
//   const min = Math.floor(time % 3600);
//   if (hour) {
//     return formatBit(hour) + ':' + formatBit(Math.floor(min / 60)) + ':' + formatBit(Math.floor(time % 60));
//   } else {
//     return formatBit(Math.floor(min / 60)) + ':' + formatBit(Math.floor(time % 60));
//   }
// }

/**
 * 时间间隔 counterTime
 */
export const counterTime = (endTime:any) => {
  if (typeof endTime == 'number') {
    endTime = new Date(endTime);
  } else if (isNaN(Date.parse(endTime))) {
    endTime = new Date(Date.parse(endTime.replace(/-/g, "/")));
  } else {
    endTime = new Date(Date.parse(endTime));
  }

  var now:any = new Date();
  var range:any = (endTime - now) / 1000,
    counter:any = {};
  if (range < 0) {
    range = 0 - range;
    counter.valid = false;
  } else {
    counter.valid = true;
  }
  counter.day = operateTime(Math.floor(range / (24 * 60 * 60)));
  counter.hour = operateTime(Math.floor(range % (24 * 60 * 60) / (60 * 60)));
  counter.min = operateTime(Math.floor(range % (24 * 60 * 60) % (60 * 60) / 60));
  counter.sec = operateTime(Math.floor(range % (24 * 60 * 60) % (60 * 60) % 60));
  return counter;
};

export const counterTimeBySec = (sec:any) => {
  var range:any = sec;
  var counter:any = {};
  if (range < 0) {
    range = 0 - range;
    counter.valid = false;
  } else {
    counter.valid = true;
  }

  counter.day = operateTime(Math.floor(range / (24 * 60 * 60)));
  counter.hour = operateTime(Math.floor(range % (24 * 60 * 60) / (60 * 60)));
  counter.min = operateTime(Math.floor(range % (24 * 60 * 60) % (60 * 60) / 60));
  counter.sec = operateTime(Math.floor(range % (24 * 60 * 60) % (60 * 60) % 60));
  return counter;
};

/*
 * 倒计时显示 countDown by sec //通过秒数倒计时
 * */
export const countDownSec = (sec:any, cbk:any) => {
  return setInterval(() => {
    cbk && cbk(counterTimeBySec(sec))
  }, 1000)
};

/*
 * 倒计时显示 countDown
 * */
export const countDown = (time:any, cbk:any) => {
  return setInterval(() => {
    cbk && cbk(counterTime(time))
  }, 1000)
};

/*
 * 间隔时间 intervalTime
 * */
export const intervalTime = (interval:any, format:any) => {
  format = format || "min:sec"
  return format
    .replace("min", operateTime(Math.floor(interval / 60)))
    .replace("sec", operateTime(Math.floor(interval % 60)))
  return format;
};


export const operateTime = (time:any) => {
  return time < 10 ? '0' + time : time
}

// export const dateStr = (date:number, format:any) =>{
//   //获取js 时间戳
//   var time:number=new Date().getTime();
//   time=parseInt((time-date*1000)/1000);

//   //存储转换值
//   var s;
//   if(time<60*10) {//十分钟内
//     return '刚刚';
//   } else if ((time<60*60)&&(time>=60*10)){
//     //超过十分钟少于1小时
//     s = Math.floor(time/60);
//     return  s+"分钟前";
//   } else if ((time<60*60*24)&&(time>=60*60)){
//     //超过1小时少于24小时
//     s = Math.floor(time/60/60);
//     return  s+"小时前";
//   } else if (( time < 60*60*24*3 ) && ( time >= 60*60*24 )) {
//     //超过1天少于3天内
//     s = Math.floor(time/60/60/24);
//     return s+"天前";
//   } else {
//     //超过3天
//     return formatTime(date * 1000, format)
//   }
// }

// export const formatTimeStr = (time:any) => {
//   const dayText:any = {
//     1: '一',
//     2: '二',
//     3: '三',
//     4: '四',
//     5: '五',
//     6: '六',
//     0: '日'
//   }
//   const curTime = moment()
//   const yesterday = curTime.clone().subtract(1, 'days').startOf('day')
//   const t = moment.unix(time)
//   const tDay = t.startOf('day')
//   const aWeek = curTime.clone().subtract(7, 'days').startOf('day')
//   if (tDay.isSame(curTime.startOf('day'), 'd')) { // 是今天
//     return formatTime(time * 1000, 'HH:mm')
//   } else if (tDay.isSame(yesterday, 'd')) { // 昨天
//     return `昨天 ${formatTime(time * 1000, 'HH:mm')}`
//   } else if (tDay.isAfter(aWeek)) { // 七天内
//     const day = t.day()
//     return `星期${dayText[day]} ${formatTime(time * 1000, `HH:mm`)}`
//   } else {
//     return formatTime(time * 1000, 'yyyy/MM/dd')
//   }
// }

export const getTimeDict = (time:any) => {
  let date
  if (time.toString().length >= 13) {
    date = new Date(time); //如果date为13位不需要乘1000
  } else {
    date = new Date(time * 1000);
  }
  let year = date.getFullYear();
  let month = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
  let day = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
  let hour = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
  let minute = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
  let second = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());

  let timedict = {
    Year: year,
    Month: month,
    Day: day,
    Hour: hour,
    Minute: minute,
    Second: second,
  }
  return timedict;
}

export const isToday = (time:any) => {
  let date
  if (time.toString().length >= 13) {
    date = new Date(time); //如果date为13位不需要乘1000
  } else {
    date = new Date(time * 1000);
  }
  return new Date().toDateString() === date.toDateString();
}

export const isYesterday = (time:any) => {
  let dayTime = 24 * 60 * 60 * 1000; // 一天时间戳
  let todayTime = new Date().getTime()
  let yesterdayTime = todayTime - dayTime
  let yesterdayDate = new Date(yesterdayTime)
  let date
  if (time.toString().length >= 13) {
    date = new Date(time); //如果date为13位不需要乘1000
  } else {
    date = new Date(time * 1000);
  }
  return yesterdayDate.toDateString() === date.toDateString();
}

/*
 * 获取随机串
 * */
// export const randomNum = (len:any) => {
//   var salt = '';
//   for (var i = 0; i < len; i++) {
//     var tmp = parseInt(Math.floor(Math.random() * 10));
//     if (!tmp) {
//       tmp = '2';
//     }
//     salt += tmp;
//   }
//   return salt;
// };

/*
 * 本地存储
 * */
// export const storage = {
//   method: 'localStorage',
//   set: (key, value, time, method) => {
//     var data, valid;
//     key = [getShopId(), (key || 'duanshu')].join('.');
//     method = method || storage.method;
//     data = {
//       value: JSON.stringify(value)
//     };
//     if (typeof time == 'number') {
//       // 这里是一个坑 timestamp 是毫秒
//       // time 单位为 秒
//       if (time.toString().length == 10) {
//         // 时间点
//         data.timestamp = time * 1e3;
//       } else {
//         // 时间间隔
//         valid = time * 1e3;
//         data.timestamp = (new Date).getTime() + valid
//       }
//     } else {
//       data.timestamp = null;
//     }
//     return window[method].setItem(key, JSON.stringify(data));
//   },
//   get: (key, method) => {
//     var data;
//     key = [getShopId(), (key || 'duanshu')].join('.');
//     method = method || storage.method;
//     data = JSON.parse(window[method].getItem(key));
//     return data && data.value ? data.timestamp === null ? JSON.parse(data.value) : (new Date).getTime() < data.timestamp && JSON.parse(data.value) || !1 : !1;
//   },
//   remove: (key, method) => {
//     key = [getShopId(), (key || 'duanshu')].join('.');
//     method = method || storage.method;
//     window[method].removeItem(key);
//   },
//   clear: (method) => {
//     method = method || storage.method;
//     window[method].clear();
//   }
// };

// export const sessionStorage = {
//   method: 'sessionStorage',
//   set: (key, value, time) => {
//     return storage.set(key, value, time, sessionStorage.method)
//   },
//   get: (key) => {
//     return storage.get(key, sessionStorage.method)
//   },
//   remove: (key) => {
//     return storage.remove(key, sessionStorage.method)
//   },
//   clear: () => {
//     return storage.remove(sessionStorage.method)
//   }
// }

/*
 * 获取当前链接参数
 * */
// export const getUrlParam = function (key, type) {
//   var search_url = '';
//   if (type === 'hash') {
//     search_url = window.location.hash && (window.location.hash.indexOf('?') > -1) && ('?' + window.location.hash.split('?')[1])
//   } else {
//     search_url = window.location.search
//   }
//   if (typeof key === 'undefined') {
//     var param = {};
//     search_url && search_url.replace(/([^=?&]*)=([^&]*)/g,
//       function (item, key, value) {
//         return param[decodeURIComponent(key)] = decodeURIComponent(value);
//       });
//     return param;
//   }
//   var pattern = new RegExp("(^|&)" + key + "=([^&]*)(&|$)"),
//     match = search_url && search_url.substr(1).match(pattern) || null;
//   return match !== null ? decodeURIComponent(match[2]) : null;
// };

/*
 * 序列化
 * */
// export const codeParam = function (param) {
//   let codeParam = [];
//   for (let i in param) {
//     if (param[i] != undefined) {
//       codeParam.push(encodeURIComponent(i) + '=' + encodeURIComponent(param[i] == null ? "" : param[i]))
//     }
//   }
//   return codeParam.join('&');
// };

/**
 * 设置cookie
 *
 * @param name 名称 value 内容 options 其它参数
 * @returns cookie的值 或者是设置cookie
 */
// export const cookie = (name, value, options) => {
//   if (typeof value != 'undefined') {
//     options = options || {};
//     if (value === null) {
//       value = '';
//       options.expires = -1;
//     }
//     var expires = '';
//     if (options.expires && (typeof options.expires == 'number' || options.expires.toUTCString)) {
//       var date;
//       if (typeof options.expires == 'number') {
//         date = new Date();
//         date.setTime(date.getTime() + (options.expires * 24 * 60 * 60 * 1000));
//       } else {
//         date = options.expires;
//       }
//       expires = '; expires=' + date.toUTCString();
//     }
//     var path = options.path ? '; path=' + options.path : '';
//     var domain = options.domain ? '; domain=' + options.domain : '';
//     var secure = options.secure ? '; secure' : '';
//     document.cookie = [name, '=', encodeURIComponent(value), expires, path, domain, secure].join('');
//   } else {
//     var cookieValue = null;
//     if (document.cookie && document.cookie != '') {
//       var cookies = document.cookie.split(';');
//       for (var i = 0; i < cookies.length; i++) {
//         var cookie = cookies[i].replace(/^\s+|\s+$/g, '');
//         if (cookie.substring(0, name.length + 1) == (name + '=')) {
//           cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
//           break;
//         }
//       }
//     }
//     return cookieValue;
//   }
// };

/*
 * 移动设备平台
 * */
// export const mobileDevice = ( isplat ) => {
// 	var mbldevice = navigator.userAgent.toLowerCase();
//   // console.log(mbldevice,'mbldevice');
//   console.log('mbldevice: ', mbldevice)
// 	if( isplat ){
// 		if ( /micromessenger/.test( mbldevice ) ){
//       if (window.location.href.indexOf(lotteryHost) > -1) {
//         return "lottery-wechat";
//       } else {
//         return "wechat";
//       }
// 		} else if( /m2oapp/.test( mbldevice ) || /m2osmartcity/.test( mbldevice ) ){
// 			return "smartcity";
// 		} else if( /duanshu_sdk/.test( mbldevice ) ){ // duanshu_sdk/1.0
// 			return "duanshu_sdk";
// 		} else if( /duanshu/.test( mbldevice ) ){
// 			return "duanshu";
// 		} else if( /dingdone/.test( mbldevice ) ){
// 			return "dingdone";
// 		} else if( /kdtunion/.test( mbldevice ) ){
// 			return "dingdone";
// 		// }else if( /dingtalk/.test( mbldevice ) || /aliapp/.test( mbldevice )){
// 		// 	return "dingding";
//     } else if( /aweme/.test( mbldevice ) ){
//       console.log("有没有进来");
//       return "douyin"
//     }
// 		return "other";
// 	}

// 	if ( /iphone|ipod|ipad/gi.test( mbldevice ) ){
// 		return "iOS";
// 	}else if ( /android/gi.test( mbldevice ) ){
// 		return "Android";
// 	}else{
// 		return "Other";
// 	}
// };

// export const refreshUrl = (url, key) => {
//   key = (key || 'randomStamp') + '=';
//   url = url || window.location.href;
//   var reg = new RegExp(key + '\\d+'),
//     random = randomNum(6);
//   if (url.indexOf(key) > -1) {
//     return url.replace(reg, key + random);
//   } else {
//     if (url.indexOf('?') > -1) {
//       var urlArr = url.split('?');
//       return urlArr[0] + '?' + key + random + (urlArr[1] ? '&' + urlArr[1] : '');
//     } else {
//       if (url.indexOf('#') > -1) {
//         return url.split('#')[0] + '?' + key + random + window.location.hash;
//       } else {
//         return url + '?' + key + random;
//       }
//     }
//   }
// };

// export const replaceUrl = (url) => {
//   if (url) {
//     url = url.replace(/\&*preview=1/, '')
//       .replace(/\&*state=[0-9]+/, '')
//       .replace(/\&*msg=.*/, '')
//       .replace(/\&*code1=.*/, '')
//       .replace(/\&*code=.*/, '');
//     return url;
//   }
//   return window.location.origin + window.location.pathname + window.location.hash.split('?')[0];
// };

export const originUrl = () => {
  return window.location.origin + window.location.pathname;
};

export const removeUrlParameter = (url:any, parameter:any) => {
  var a = document.createElement('a')
  a.href = url
  var queryString = a.search.slice(1);

  if (queryString) {
    // Get first part, and remove from array
    var urlBase = a.origin + a.pathname;

    // Join it back up
    var prefix = encodeURIComponent(parameter) + '=';
    var parts = queryString.split(/[&;]/g);

    // Reverse iteration as may be destructive
    for (var i = parts.length; i-- > 0;) {
      // Idiom for string.startsWith
      if (parts[i].lastIndexOf(prefix, 0) !== -1) {
        parts.splice(i, 1);
      }
    }

    url = urlBase + '?' + parts.join('&') + a.hash;
  }

  return url;
}

// shoppingInfo 在 app.vue created 钩子异步获取并写入 sessionStorage
// 因此 这里的第一次进入应用时获取 shoppingInfo 是取不到的(异步未返回)
// 下方 title 改为 调用时实时从缓存获取
// const shoppingInfo = storage.get('shoppingInfo', 'sessionStorage')
// export const windowTitle = (title) => {
//   let shoppingInfo = storage.get('shoppingInfo', 'sessionStorage')
//   if (title) {
//     window.document.title = title
//     console.log('window.document.title: ', window.document.title)
//   } else {
//     let t = ''
//     if (shoppingInfo) {
//       t = shoppingInfo.shop && shoppingInfo.shop.title
//     }
//     window.document.title = t
//     console.log('window.document.title2: ', window.document.title)
//   }
  /*
  if (mobileDevice() == 'iOS') {
    const hackIframe = document.createElement('iframe')
    hackIframe.style.display = 'none'
    hackIframe.src = '//oss-web.duanshu.com/ds_projects/ds_app_tech/static/img/favicon.ico?random=' + Math.random()

    document.body.appendChild(hackIframe)

    setTimeout(_ => {
      document.body.removeChild(hackIframe)
    }, 300)
  }
  */
// };
/*
 * 图片串接 createImgsrc
 * */
export const createImgsrc = (data:any = {}, options:any = {}) => {
  // var src, op_str = '';
  var src = '';
  var op_str = 'imageMogr2/auto-orient';
  var op_str2 = 'thumbnail'
  var size = ''
  var gifReg = /.gif$/

  if (options.width || options.height) {
    let width = options.width || ''
    let height = options.height || ''
    size = width + 'x' + height
  }

  if (data !== null && typeof data === 'object' && data.host) {
    data.host = data.host.replace('duanshu-1253562005.cossh.myqcloud.com', 'duanshu-1253562005.image.myqcloud.com')
    options.orient = 1;
    if (data.query && data.query.indexOf(op_str) > -1) {
      data.query = data.query.replace(/(.*?)imageMogr2\/auto-orient\//, '')
    }

    if (data.query) {
      data.query = data.query.replace(/cut\/([\d-]*)x([\d-]*)x([\d-]*)x([\d-]*)/, function($0:any, $1:any, $2:any, $3:any, $4:any){
        let a = $1 > 0 ? $1 : 0
        let b = $2 > 0 ? $2 : 0
        let c = $3 > 0 ? $3 : 0
        let d = $4 > 0 ? $4 : 0
        return `cut/${a}x${b}x${c}x${d}`
      })
    }

    let file = data.host + data.file
    let query:any = []
    if (gifReg.test(file)) { // 如果是gif，不做裁剪
      query = []
    } else if (data.query && data.query.indexOf('auth_key') > -1) { // 如果参数带有 auth_key 也不做裁剪
      query = [data.query]
    } else {
      query = [op_str, op_str2, size, data.query]
    }
    src = urlFormat2(file, query)

    return src;
  } else if (typeof data == 'string') {
    if (!data) return ''
    data = data.replace('duanshu-1253562005.cossh.myqcloud.com', 'duanshu-1253562005.image.myqcloud.com')
    // 如果返回的图片是字符串，需要中间插入宽高
    // 如果服务端返回图片有处理，需插入 'thumbnail' 字段

    if (!gifReg.test(data) || data.indexOf('auth_key') > -1) { // gif 或者有 auth_key 字段 不需要裁剪
      if (data.indexOf(op_str) > -1) {
        let insertText = [op_str, op_str2, size].join('/')
        data = data.replace(op_str, insertText)
      } else {
        // 如果不是裁剪图片，如果有配置宽高，则拼宽高，否则输出原图
        data = urlFormat2(data, [op_str, op_str2, size])
      }
    }

    return data
  }
  return ''
};
export const contentRouter:any ={
	article: {
		brief: '/brief/article/{content_id}',
		form: '/form/article/{content_id}',
    title: '图文'
	},
	audio: {
		brief: '/brief/audio/{content_id}',
		form: '/form/audio/{content_id}',
    title: '音频'
	},
	video: {
		brief: '/brief/video/{content_id}',
		form: '/form/video/{content_id}',
    title: '视频'
	},
	live: {
		brief: '/brief/live/{content_id}',
		form: '/brief/live/{content_id}',
    title: '直播'
	},
	content_live: {
		brief: '/brief/live/{content_id}',
		form: '/brief/live/{content_id}',
    title: '直播'
	},
	class_live: {
		brief: '/courseStudio/{course_class_id}/{course_id}?classContentId={content_id}',
		form: '/courseStudio/{course_class_id}/{course_id}?classContentId={content_id}',
    title: '直播'
	},
	camp_live: {
		brief: '/courseStudio/{parent_content_id}/{parent_content_id}?classContentId={content_id}&type=trainingcampclass',
		form: '/courseStudio/{parent_content_id}/{parent_content_id}?classContentId={content_id}&type=trainingcampclass',
    title: '直播'
	},
	column: {
		brief: '/brief/column/{content_id}',
		form: '/form/column/{content_id}',
    title: '专栏'
	},
	course: {
		brief: '/form/cource/{content_id}/{course_type}',
		form: '/form/cource/{content_id}/{course_type}',
    title: '课程'
	},
	course_type: {
		form: '/form/{course_type}/{content_id}/{chapter_id}/{course_id}',
    title: '课时'
	},
	note: {
		brief: '/community/postDetail/{content_id}',
		form: '/community/postDetail/{content_id}',
		title: '小社群'
	},
	entity: {
		brief: '/commodity/detail/{content_id}',
		form: '/commodity/detail/{content_id}',
		title: '实物商品'
	},
	aimicrolecture: {
		brief: '/aicourse/detail/{content_id}',
		form: '/aicourse/detail/{content_id}',
		title: 'A.I.微课'
	},
	offlinecourse: {
		brief: '/offline/detail/{content_id}',
		form: '/offline/detail/{content_id}',
		title: '在线招生'
	},
	smallclass: {
		brief: '/smallclass/detail/{content_id}',
		form: '/smallclass/detail/{content_id}',
		title: '小班课'
	},
	largeclass: {
	  brief: '/largeclass/detail/{content_id}',
	  form: '/largeclass/detail/{content_id}',
	  title: '系列直播'
	},
	card: {
		brief: '/taskcard/detail/{content_id}',
		form: '/taskcard/detail/{content_id}',
		title: '打卡'
	}
};
export const filterRouter = (data:any, extraInfo:any, cbk:any) => {
  if (data[0]) {
    data.forEach((item:any, _:any) => {
      if (item.payment_type) {
        item.payment_type = parseInt(item.payment_type)
      }
      if (typeof item.is_display === 'undefined') {
        item.is_display = 1
      }
      let extraObj = extraInfo || {}
      let content:any
      if (extraObj.key === 'notice') {
        content = item.link_info
      } else {
        content = item
      }
      let contentType = extraObj.type || content.content_type || content.type
      if (item.payment_type === 1 && extraObj.key === 'classify') {
        item.router = ''
      } else if (contentRouter[contentType]) {
        let router
        if (extraObj.isbuy || content.payment_type === 3) {
          router = contentRouter[contentType].form
        } else {
          router = contentRouter[contentType].brief
        }
         // 搜索功能的id为 content.id
        item.router = router
          .replace(/{(.*?)}/g, (string:any, key:any) => {
            let value = ''
            if (content[key]) { // 内容
              // return string.replace('{' + key + '}', content[key])
              value = content[key]
            } else if (contentType === 'column' && key === 'content_id') { // 专栏
              // return string.replace('{' + key + '}', content.column_id || content.id)
              value = content.column_id || content.id
            } else if (contentType === 'course') { // 课程
              if (content.course && content.course[key]) {
                // return string.replace('{' + key + '}', content.course[key])
                value = content.course[key]
              } else {
                // return string.replace('{' + key + '}', content.id)
                value = content.id
              }
            } else if (content.id) {
              // return string.replace('{' + key + '}', content.id)
              value = content.id
            }
            string = string.replace('{' + key + '}', value)
            return string
          })
      } else {
        item.router = ''
      }
      cbk && cbk(item)
    })
  }
}

// export const createImgsrc2 = (data = {}) => {
//   var src = '';
//   if (data !== null && typeof data == 'object' && data.host) {
//     console.log(data.host, 'data.host')
//     if (data.host.indexOf('http:') == -1 && data.host.indexOf('https:') == -1) {
//       src = 'http:' + data.host + data.file
//     } else {
//       src = data.host + data.file
//     }
//     return src;
//   } else if (typeof data == 'string') {
//     return data
//   }
//   return;
// };

// export const getOriginImageSrc = (data = {}) => {
//   let src = createImgsrc2(data)
//   const i = src.indexOf('?')
//   if (i === -1) {
//     return src
//   } else {
//     const finalSrc = src.substr(0, i)
//     return finalSrc
//   }
// }

// export const imgResize = (url, w, h) => {
//   let query = '?imageMogr2/auto-orient/thumbnail/'+w+'x'+h+''
//   let gifReg = /.gif$/
//   if (typeof url === 'string' && !gifReg.test(url)) {
//     return url.indexOf('?') === -1 ? url+query:url
//   } else {
//     return url
//   }
// }

// /*
//  * 环境判断
//  * */
// export const envSetting = () => {
//   const host = window.location.host
//   if (host.indexOf('sina') !== -1) {
//     return 'sina-develop'
//   }
//   if (host === 'dsh5.ddapp.com') { // 在预发布环境测试全自定义域名
//     return 'prerelease'
//   }

//   if (!host) {
//     return 'develop'
//   }

//   // 测试环境 xxx.test.duanshu.com / test-h5.duanshu.com/{shopId}
//   // 预发布环境 xxx.pre.duanshu.com / pre-h5.duanshu.com/{shopId}
//   // 线上环境 xxx.duanshu.com / xxx.duanshu.com/{shopId} / h5.duanshu.com/{shopId}
//   const hostArr = host.split('.')
//   const topHost = hostArr[0]
//   const secHost = hostArr[1]
//   let env = 'production'

//   if (topHost.indexOf('test-') !== -1) {
//     env = 'develop'
//   } else if (topHost.indexOf('pre-') !== -1) {
//     env = 'prerelease'
//   }

//   if (secHost === 'test') {
//     env = 'develop'
//   } else if (secHost === 'pre') {
//     env = 'prerelease'
//   }
//   return env
// };

// /*
//  *延迟加载资源
//  * */
// export const loadCss = (href) => {
//   if (!href) {
//     console.warn('没有需要加载的资源');
//     return;
//   }
//   var fontLink = document.getElementById("iconfont");
//   if (fontLink) {
//     return false;
//   }
//   var fontStyle = document.createElement("link");
//   fontStyle.type = "text/css";
//   fontStyle.rel = "stylesheet";
//   fontStyle.href = href;
//   fontStyle.id = "iconfont";
//   fontStyle.onload = fontStyle.onerror = function () {
//     console.info('done');
//   }
//   var head = document.getElementsByTagName("head")[0];
//   head.insertBefore(fontStyle, head.lastChild);
// };

// export const locadJs = (js) => {
//   var script = document.createElement( 'script' );
//   script.setAttribute('src', js);
//   document.body.appendChild(script);
// };

// /*
//  * 队列开始
//  * */
// export const queueOpen = (type, cbk) => {
//   if (!type || !cbk) {
//     console.warn('队列开启失败')
//     return false
//   }
//   if (!(window.queue || (window.queue = {}))[type]) {
//     window.queue[type] = {}
//   }
//   var cur_queue = window.queue[type]
//   if (cur_queue.checking) {
//     (cur_queue.wait || (cur_queue.wait = [])).push(cbk)
//     return true
//   }
//   cur_queue.checking = true
//   return false
// };
// /*
//  * 队列关闭
//  * */
// export const queueClose = (type, ...data) => {
//   if (!type || !window.queue[type]) {
//     console.warn('队列关闭失败')
//     return false;
//   }
//   var cur_queue = window.queue[type]
//   if (cur_queue.checking) {
//     if (cur_queue.wait) {
//       for (var i = 0; i < cur_queue.wait.length; i++) {
//         cur_queue.wait[i](...data)
//       }
//       cur_queue.wait = []
//     }
//     cur_queue.checking = false
//   }
// };
// /*
//  * 白名单判断
//  *
//  * */
// export const isWhiteShopping = () => {
//   const classData = Object.keys(document.body.classList)
//   let whiteShopping = false
//   classData.map((item) => {
//     if (document.body.classList[item] === 'whiteShopping') {
//       whiteShopping = true
//       return false
//     }
//   })
//   if (whiteShopping) {
//     return true
//   } else {
//     return false
//   }
//   //开启验证白名单
// }

// export const dumpObject = (object) => {
//   var s = ''
//   for (var property in object) {
//     s = s + '\n ' + property + ': ' + object[property]
//   }
//   console.log(s)
// }

// export const routeObj = ({ id, type, courseType, activityId, isSubscribe }) => {
//   let name = ''
//   if (type === 'course') {
//     name = 'Fmcource'
//   } else if (type === 'video') {
//     name = isSubscribe ? 'Fmvideo' : 'Brivideo'
//   } else if (type === 'column') {
//     name = isSubscribe ? 'Fmcolumn' : 'Bricolumn'
//   } else if (type === 'article') {
//     name = isSubscribe ? 'Fmarticle' : 'Briarticle'
//   } else if (type === 'card') {
//     name = isSubscribe ? 'Bricard' : 'Bricard'
//   } else if (type === 'audio') {
//     name = isSubscribe ? 'Fmaudio' : 'Briaudio'
//   } else if (type === 'live') {
//     name = 'Brilive'
//   } else if (type === 'community') {
//     name = 'CommunityDetail'
//   } else if (type === 'trainingcampclass') {
//     name = 'Britrainingcampclass'
//   }

//   let params = {
//     id
//   }

//   if (courseType) {
//     params.type = courseType
//   }
//   let query = {}
//   if (activityId) {}

//   let options = {
//     name,
//     params,
//     query
//   }
//   return options
// }

// export const isEmpty = (e) => {
//   if (e === undefined || e === null) {
//     return true
//   }
//   if ((typeof (e) === 'string' || Array.isArray(e)) && !e.length) {
//     return true
//   }
//   if (JSON.stringify(e) === '{}') {
//     return true
//   }
//   return false
// }

// export const urlFormat = (url, params) => {
//   if (typeof(params) !== 'object') return
//   let paramArr = []
//   let finalUrl = ''
//   for (let i in params) {
//     if (i && !isEmpty(params[i])) {
//       paramArr.push(i + '=' + params[i])
//     }
//   }
//   if (!paramArr.length) {
//     return url
//   }
//   let hash = url.split('#')[1] || url
//   if (hash.indexOf('?') > -1) {
//     finalUrl = url + '&' + paramArr.join('&')
//   } else {
//     finalUrl = url + '?' + paramArr.join('&')
//   }
//   return finalUrl
// }

export const urlFormat2 = (url:any, params:any) => {
  if (!params) return url

  let finalUrl = ''
  let paramArr = []

  if (typeof params === 'string') {
    paramArr.push(params)
  } else {
    paramArr = params
  }

  if (url.indexOf('?') < 0) {
    url += '?'
  }

  finalUrl = url += paramArr.join('/')
  return finalUrl
}

// export const routerToUrl = (router, routerConfig) => {
//   let {name, query, params} = routerConfig
//   let routerC = {
//     name,
//     query,
//     params
//   }
//   let { href } = router.resolve(routerC)
//   let url = window.location.href.split('#')[0] + href
//   return url
// }

// // 获取链接参数，默认 window.location.hash
// export const getQueryString = (name, url) => {
//   var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
//   let u = ''
//   var r = null
//   if (url) {
//     u = url || window.location.search
//     r = u.substr(u.indexOf('?') + 1).match(reg)
//   } else if (window.location.hash.indexOf('?') > -1) {
//     r = window.location.hash.split('?')[1].match(reg)
//   }
//   if (r != null) return unescape(r[2])
//   return null
// }

// // 秒数倒计时，默认 60 秒，间隔 1 秒
// // seconds: 秒数(单位秒)，interval: 间隔(单位秒)
// export const countTimer = ({time, interval, fn}) => {
//   time = time || 60
//   interval = interval || 1
//   if (isNaN(time) || isNaN(interval) || time <= 0) return
//   time = Math.floor(time)
//   let timer = setInterval(() => {
//     time--
//     if (time <= 0) {
//       clearInterval(timer)
//     }
//     fn && fn(time)
//   }, interval * 1000)
// }

// // 检测手机号
// export const isMobile = (num) => {
//   let reg = /^[1][0-9]{10}$/
//   return reg.test(num)
// }

// 检测邮箱格式
export const isEmail = (val:any) => {
  let reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
  return reg.test(val)
}

// // 计算字长度，汉字字符 += 2，其他字符 += 1
// export const checkWordSize = (text) => {
//   if (typeof text !== 'string' || !text.length) {
//     return text
//   }
//   let size = 0
//   let reg = /[^\x00-\xff]/
//   for (let i = 0; i < text.length; i++) {
//     if (reg.test(text[i])) {
//       size += 2
//     } else {
//       size += 1
//     }
//   }
//   return size
// }

// // n 个汉字 = n * 2 个字符
// export const sliceWord = ({text, chineseLen, wordLen, omit = false}) => {
//   if (typeof text !== 'string' || !text.length) {
//     return text
//   }
//   let opoint = omit ? '...' : ''
//   if (text.length > chineseLen) {
//     let sText = text.slice(0, chineseLen)
//     // 如果裁剪的文字全是中文，直接输出
//     if (checkWordSize(sText) >= wordLen) {
//       return sText + opoint
//     } else {
//       // 不全为中文
//       // 裁剪12位，如果长度12字节，直接输出
//       sText = text.slice(0, wordLen)
//       let size = checkWordSize(sText)
//       if (size <= wordLen) {
//         return sText + opoint
//       } else {
//         // 超过 wordLen，从后面开始裁剪
//         let subText = sText
//         for (let i = 0; i < subText.length; i++) {
//           subText = subText.substr(0, subText.length - 1)
//           if (checkWordSize(subText) <= wordLen) {
//             break
//           }
//         }
//         return subText + opoint
//       }
//     }
//   } else {
//     // 长度不足 chineseLen
//     return text
//   }
// }

// // 分类级数配置
// export const classPageStyle = (style) => {
//   let map = {
//     'category_one': 1,
//     'category_two': 2,
//     'category_three': 3,
//     'category_flatten': 0,
//   }
//   return map[style] || ''
// }

// export const linkTo = ($router, link, userInfo, method = 'push') => {
//   let flag = 1
//   if (link.type === 'outLink') { // 外部链接
//     let redirectUri = link.name
//     let a = window.document.createElement('a')
//     a.href = redirectUri
//     if (userInfo && a.host.indexOf('xiuzan') !== -1) {
//       let rekey = (link.name.indexOf('?') > -1) ? '&' : '?'
//       redirectUri += `${rekey}userinfo=${userInfo}`
//     }
//     window.location.href = redirectUri
//     flag = 0
//   } else if (link.type === 'outlink') { // 外部链接
//     window.location.href = link.id
//     flag = 0
//   } else if (link.type === 'member_card') { // 会员卡
//     $router[method]({ name: link.id === 'index' ? 'Card' : 'CardDetail', params: {id: link.id === 'index' ? '' : link.id} })
//     flag = 0
//   } else if (link.type === 'question_zone') { // 付费问答
//     $router[method]({ name: 'consult', params: { qtnAreaId: link.id } })
//     flag = 0
//   } else if (link.type === 'course') { // 课程详情
//     $router[method]({ name: 'Fmcource', params: {id: link.id, type: link.course_type} })
//     flag = 0
//   } else if (link.type === 'promotion') { // 推广计划
//     $router[method]({ name: 'Popularizeplan', params: {id: 'mine'} })
//     flag = 0
//   } else if (link.type === 'class') { // 分类内容列表
//     $router[method]({ name: 'ClassifyTransfer', query: {id: link.id, title: link.name} })
//     flag = 0
//   } else if (link.type === 'limit_purchase') { // 限时活动
//     $router[method]({
//       name: link.id === 'index' ? 'LimitIndex' : 'LimitActivity',
//       params: {
//         id: link.id === 'index' ? '' : link.id
//       }
//     })
//     flag = 0
//   } else if (link.type === 'community') { // 小社群
//     $router[method]({ name: link.id === 'index' ? 'CommunityList' : 'CommunityDetail', params: {id: link.id === 'index' ? '' : link.id} })
//     flag = 0
//   } else if (link.type === 'coupon') { // 优惠券详情
//     $router[method]({ name: 'CouponDetail', params: {couponId: link.id} })
//   } else if (link.type === 'provingground') { // 训练营
//     $router[method]({ name: 'Briprovingground', params: {id: link.id} })
//   } else if (link.type === 'trainingcampclass') { // 试练场
//     $router[method]({ name: 'Britrainingcampclass', params: {id: link.id} })
//   } else if (link.type === 'promoter') { // 推广计划
//     // $router[method]({ name: 'Popularize' })
//     $router[method]({ name: 'Popularizeplan', params: {id: 'mine'} })
//     flag = 0
//   } else if (link.type === 'feedback' || link.type === 'survey') { // 问卷/表单
//     let redirectUri = link.url
//     if (userInfo) {
//       let rekey = (redirectUri.indexOf('?') > -1) ? '&' : '?'
//       redirectUri += `${rekey}userinfo=${userInfo}`
//       window.location.href = redirectUri
//       flag = 0
//     } else {
//       flag = 2
//     }
//   } else if (link.type === 'fight_group') { // 拼团
//     if (link.id === 'index') {
//       $router[method]({name: 'FightGroupList'})
//       flag = 0
//     } else if (link.related_content_type === 'course') {
//       $router[method]({name: 'Fmcource', params: {id: link.related_content_id, type: link.course_type}})
//       flag = 0
//     } else if (link.related_content_type === 'offlinecourse') {
//       if (link.related_content_id === 'index') {
//         $router[method]({name: 'offlineList'})
//         flag = 0
//       } else {
//         $router[method]({name: 'offlineDetail', params: {goodsId: link.related_content_id}})
//         flag = 0
//       }
//     } else if (link.related_content_type === 'membercard') {
//       // 会员卡
//       $router[method]({ name: 'CardDetail', params: {id: link.related_content_id }})
//       flag = 0
//     } else {
//       $router[method]({name: 'Bri' + link.related_content_type, params: {id: link.related_content_id}})
//       flag = 0
//     }
//   } else if (link.type === 'offline_course') { // 线下课程
//     if (link.id === 'index') {
//       $router[method]({name: 'offlineList'})
//       flag = 0
//     } else {
//       $router[method]({name: 'offlineDetail', params: {goodsId: link.id}})
//       flag = 0
//     }
//   } else if (link.type === 'entity') { // 实物商品
//     $router[method]({
//       name: 'CommodityDetail',
//       params: {
//         productId: link.id
//       }
//     })
//     flag = 0
//   } else if (link.type === 'gift_packages') {
//     $router[method]({
//       name: 'StudentPresent',
//       params: {
//         id: link.id
//       }
//     })
//     flag = 0
//   } else if (link.type === 'wework_gift_package') {
//     $router[method]({
//       name: 'weworkActivity',
//       params: {
//         id: link.id
//       }
//     })
//     flag = 0
//   } else if (['prize_wheels', 'marquee'].indexOf(link.type) > -1) { // 转盘抽奖
//     $router[method]({
//       name: 'LotteryTransit',
//       query: {
//         id: link.id,
//         model: link.type
//       }
//     })
//   } else if (link.type !== 'none' && link.id) { // 图文/音频/视频/专栏/直播/打卡/微课/训练营/试练场详情
//     link.type = link.type === 'alive' ? 'live' : link.type
//     if (link.type === 'card' && link.id === 'index') {
//       $router[method]({ name: 'TaskcardList' })
//       flag = 0
//     }else {
//       $router[method]({ name: `Bri${link.type}`, params: {id: link.id} })
//       flag = 0
//     }
//   }
//   return flag
// }

// // 跳详情通用函数
// export const toContentDetail = (type, id, that, query) => {
//   let router = {}
//   let done = false
//   switch (type) {
//     case 'aav-content':
//     case 'column':
//     case 'camp':
//     case 'trainingcampclass':
//     case 'course':
//     case 'provingground':
//     case 'repeatedpractice':
//     case 'smallclass':
//     case 'largeclass':
//       let name = 'Bri' + type
//       if (type === 'aav-content') {
//         name = 'Bri' + type
//       }
//       if (type === 'camp') {
//         name = 'Britrainingcampclass'
//       }
//       router = {
//         name,
//         params: {
//           id: id
//         }
//       }
//       break
//     case 'live':
//     case 'article':
//     case 'video':
//     case 'audio':
//       router = {
//         name: 'SingleDetail',
//         params: {
//           contentId: id
//         }
//       }
//       break
//     case 'offlinecourse':
//       router = {
//         name: 'offlineDetail',
//         params: {
//           goodsId: id
//         }
//       }
//       break
//     case 'community':
//       router = {
//         name: 'CommunityDetail',
//         params: {
//           id: id
//         }
//       }
//       break
//     case 'member-card':
//     case 'membercard':
//     case 'memberCard':
//       router = {
//         name: 'CardDetail',
//         params: {
//           id: id
//         }
//       }
//       break
//     case 'ai-course':
//     case 'aimicrolecture':
//       router = {
//         name: 'Briaimicrolecture',
//         params: {
//           id: id
//         }
//       }
//       break
//     case 'commodity':
//     // case 'trainingcampclass':
//       router = {
//         name: 'CommodityDetail',
//         params: {
//           productId: id
//         }
//       }
//       break
//     case 'card':
//       router = {
//         name: 'Bricard',
//         params: {
//           id: id
//         }
//       }
//       break
//     case 'teacher':
//       router = {
//         name: 'TeacherDetail',
//         params: {
//           id
//         }
//       }
//       break
//     case 'coupon':
//       router = {
//         name: 'CouponDetail',
//         params: {
//           couponId: id
//         }
//       }
//       break
//     case 'entity':
//       router = {
//         name: 'CommodityDetail',
//         params: {
//           productId: id
//         }
//       }
//       break
//     case 'bundling_sale':
//       router = {
//         name: 'GroupPurchaseDetail',
//         params: {
//           groupPurchaseId: id
//         }
//       }
//       break
//     case 'gift_package':
//       router = {
//         name: 'StudentPresent',
//         params: {
//           id: id
//         }
//       }
//       break
//     case 'limited_time_sale':
//       router = {
//         name: 'LimitActivity',
//         params: {
//           id: id
//         }
//       }
//       break
//     case 'wework_gift_package':
//       router = {
//         name: 'weworkActivity',
//         params: {
//           id: id
//         }
//       }
//       break
//   }
//   if (query) {
//     router.query = query
//   }
//   if (router.name) {
//     that.$router.push(router)
//     return router
//   } else if (!done) {
//     that.$toast('未定义路由')
//     return false
//   }
// }

// export class TimeTrack {

//   constructor() {
//     this.timeMap = {
//       'default': new Date()
//     }
//   }

//   start(n) {
//     this.timeMap[n] = new Date()
//   }

//   end(n) {
//     let cur = new Date()
//     let name = n || 'default'
//     let start = this.timeMap[name].getTime() || new Date()
//     this.start(name)
//     return cur.getTime() - start
//   }
// }

// export class CachePromise{
//   constructor (promiseFn, key, opt) {
//     this.promiseFn = promiseFn
//     this.key = key
//     let defaultOpt = {
//       exp: 60,
//       cacheType: 'storage'
//     }
//     this.opt = Object.assign(defaultOpt, opt)
//     return this.do.bind(this)
//   }
//   static getCache(cacheType) {
//     let cacheData = storage.get('apiCache')
//     let cur = (new Date()).getTime()
//     if (cacheType === 'vuex') {
//       cacheData = window.h5App.$store.state.globalUse.apiCache
//       cacheData = JSON.parse(JSON.stringify(cacheData))
//     }
//     Object.keys(cacheData).forEach(e => {
//       let v = cacheData[e]
//       if (cur > v.expTime) {
//         delete cacheData[e]
//       }
//     })
//     return cacheData || {}
//   }
//   static setCache(key, data, type, exp) {
//     let cacheData = CachePromise.getCache(type) || {}
//     let cur = (new Date()).getTime()
//     let cacheId = key
//     cacheData[cacheId] = {
//       expTime: cur + (exp * 1000),
//       val: data
//     }

//     if (type === 'vuex') {
//       let data = JSON.parse(JSON.stringify(cacheData))
//       window.h5App.$store.commit('SET_API_CACHE', data)
//     } else {
//       storage.set('apiCache', cacheData, 60 * 60 * 24)
//     }
//   }
//   do(promiseThis, ...arg) {
//     let textContent = promiseThis || this
//     let cache = CachePromise.getCache(this.opt.cacheType)[this.key]
//     if (cache) {
//       if (cache.val instanceof Array) {
//         return Promise.resolve(cache.val)
//       } else {
//         return Promise.resolve({
//           ...cache.val,
//           _fromCache: true
//         })
//       }
//     }
//     return this.promiseFn.apply(textContent, arg).then(e => {
//       if (!e.error && !e.message) {
//         CachePromise.setCache(this.key, e, this.opt.cacheType, this.opt.exp)
//       }
//       return e
//     }, e => {
//       return Promise.reject(e)
//     })
//   }
// }

// export class CombinePromise{
//   constructor (getPromiseFn) {
//     this.getPromise = getPromiseFn
//     this.firstPromise = null
//   }
//   do() {
//     if (!this.firstPromise) {
//       this.firstPromise = this.getPromise()
//     }
//     return this.firstPromise.then(e => {
//       this.firstPromise = null
//       return e
//     }, e => {
//       this.firstPromise = null
//       return Promise.reject(e)
//     })
//   }
// }


// /** 计算字符串信息 中文为1个 英文2个为1个字符
// *  @param {[String]} str 处理的字符串
// *  @param {[Number]} limtLength 限制的字符串长度
// */
// export const getStrInfo = (str, limtLength) => {
//   // str: 源字符串 limitStr: 返回限制的字符串 count: 换算过后的源字符串的长度 limitCount: 限制字符串换算后的长度
//   let params = { str, limtStr: null, limitCount: -1, count: -1 }

//   let len = 0
//   let limitLen = 0
//   let index = 0
//   let isDealLimit = false
//   for (let i = 0; i < str.length; i++) {
//     // 处理索引
//     if (!isDealLimit) index++
//     // 判断是否限制长度
//     if (limtLength && (len >= limtLength) && !isDealLimit) {
//       limitLen = len
//       isDealLimit = true
//     }
//     // 处理英文和中文
//     if (str.charCodeAt(i) > 127 || str.charCodeAt(i) === 94) len += 2
//     else len++
//   }
//   // 赋值数据
//   if (len) params.count = len
//   if (index && isDealLimit) {
//     params.limtStr = str.substring(0, index) // 处理截断字符串
//     params.limitCount = limitLen// 处理截断字符串长度
//   }
//   return params
// }

// /** 当前计算超出时间信息
// *  @param {[String]} dataTime 数据的时间
// *  @param {[Number]} limitInfo 限制超出的时间对象值 ex: { key: 'hour', value: '72' }
// */
// export const dealTimeExpriedInfo = (dataTime, limitInfo = {}) => {
//   let dataTimeObj = counterTime(dataTime)
//   let result = { delta: 0, isExpried: false }

//   for (let key in dataTimeObj) {
//     let val = Number(dataTimeObj[key])
//     // 判断需要转的操作
//     if (limitInfo.key === 'day' && ['day'].includes(key)) {
//       result.delta += val
//     } else if (limitInfo.key === 'hour' && ['day', 'hour'].includes(key)) {
//       if (key === 'day') result.delta += val * 24
//       else result.delta += val
//     } else if (limitInfo.key === 'min' && ['day', 'hour', 'min'].includes(key)) {
//       if (key === 'day') result.delta += val * 24 * 60
//       else if (key === 'hour') result.delta += val * 60
//       else result.delta += val
//     }
//   }
//   // 判断是否过期
//   if (result.delta !== 0 && result.delta >= limitInfo.value) {
//     result.isExpried =true
//   }
//   return result
// }

// /* 转换带有换行的字符串 */
// export const changeTextForBr = (str) => {
//   if (!str || (str && !str.indexOf('\n'))) return
//   return str.replace(/\n/g, '<br/>')
// }
// /* html转义 */
// export const noEscapeHtml = (html) => {
//   return html.replace(/[<>&"]/g, c => {
//     return {
//       '<': '&lt;',
//       '>': '&gt;',
//       '&': '&amp;',
//       '"': '&quot;'
//     }[c]
//   })
// }

// export const getContentTypeSn = (contentType) => {
//   switch (contentType) {
//     case 'course':
//       return '课程'
//     case 'card':
//       return '打卡'
//     case 'community':
//       return '小社群'
//     case 'column':
//       return '专栏'
//     case 'member_card':
//     case 'membercard':
//       return '会员卡'
//     case 'video':
//       return '视频'
//     case 'audio':
//       return '音频'
//     case 'article':
//       return '图文'
//     case 'live':
//       return '直播'
//     case 'offline':
//     case 'offline_course':
//     case 'offlinecourse':
//       return '在线招生'
//     case 'wechat_article':
//       return '公众号文章'
//     case 'aimicrolecture':
//       return 'A.I.微课'
//     case 'trainingcampclass':
//       return '训练营'
//     case 'provingground':
//       return '试练场'
//     case 'famous_teacher':
//       return '名师'
//     case 'coupon':
//       return '优惠券'
//     case 'repeatedpractice':
//       return '智适应刷题'
//     case 'entity':
//       return '实物商品'
//     case 'smallclass':
//       return '小班课'
//     case 'largeclass': // 大班课
//       return '系列直播'
//     case 'expert':
//       return '预约咨询'
//     default:
//       return '其他'
//   }
// }

// export const accMul = (arg1, arg2) => {
//   let m = 0
//   let s1 = arg1.toString()
//   let s2 = arg2.toString()
//   try {
//     m += s1.split('.')[1].length
//   } catch (e) {
//   }
//   try {
//     m += s2.split('.')[1].length
//   } catch (e) {
//   }
//   return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m)
// }

// export const getArea = (code) => {
//   if (!code) return
//   code = code.toString()
//   let provinceCode = code.slice(0, 2) + '0000'
//   let cityCode = code.slice(0, 4) + '00'
//   let countyCode = code

//   let provinceName = area.province_list[provinceCode]
//   let cityName = area.city_list[cityCode]
//   let countyName = area.county_list[countyCode]

//   return {
//     province: {
//       name: provinceName,
//       code: provinceCode
//     },
//     city: {
//       name: cityName,
//       code: cityCode
//     },
//     county: {
//       name: countyName,
//       code: countyCode
//     }
//   }
// }

// export const ossHost = '//oss-web.duanshu.com'
// export const lotteryHost = 'lottery.17xiuzan.xyz'
// export const preLotteryHost = 'pre-lottery.17xiuzan.xyz'
// export const backupHost = '17xiuzan.cn'

// // 处理url，返回 xxx-xxx-com
// export function parseHost(url) {
//   return url.replace(/http:\/\/|https:\/\//, '')
//             .replace(backupHost, 'duanshu.com')
//             .replace('/#/', '')
//             .replace('.pre', '')
//             .replace(/\./g, '-')
// }

// // 返回处理二级域名，自定义域名，例如:xxx-duanshu-com
// // h5 调试域名返回 ''
// export function getOSSHost() {
//   let href = window.location.href
//   let ossHost = ''
//   if (href.match(/h5.duanshu.com\/\w+/) === null) {
//     ossHost = parseHost(window.location.hostname)
//   }
//   return ossHost
// }

// export function urlJoint({
//   protocol = 'http',
//   host = '',
//   pathname = '/',
//   search = '',
//   hash = '#/',
//   query = {}
// }) {
//   let url = `${protocol}://${host}${pathname}${search}${hash}`
//   return urlFormat(url, query)
// }

// /* 获得当前英文字母编号 */
// export function getEnglishChar (num) {
//   let baseCode = 65 + num
//   return String.fromCharCode(baseCode)
// }
// export function urlToTagHtml(v) {
//   return v.replace(
//     /(((https?\:\/\/)|(www\.))[A-Za-z0-9]+\.[A-Za-z0-9]+[\/=\?%\-&_~`@[\]\':+!]*([^<>\"\"\s])*)/gi,
//     function(url){
//       let full_url = url;
//       if (!full_url.match('^https?:\/\/')) {
//         full_url = 'http://' + full_url;
//       }
//       let link = `<a class="g-html-a" onclick="arguments[0].stopPropagation()" style="display:inline-block;word-break: break-all;" href="${full_url}" >${url}</a>`;
//       return link;
//     }
//   )
// }

// /**
//  * 替换字符串中的字段.
//  * @param {String} str 模版字符串
//  * @param {Object} o json data
//  * @param {RegExp} [regexp] 匹配字符串的正则表达式
//  */
// export function substitute(str,o,regexp){
//   return str.replace(regexp || /\\?\{([^{}]+)\}/g, function (match, name) {
//     return (o[name] === undefined) ? '' : o[name]
//   })
// }

// const jsArray = {
//   'cos-js-sdk-v5': '//oss-web.duanshu.com/ds_projects/ds_app_tech/static/js/cos-js-sdk-v5.min.js',
//   'vod-js-sdk-v6': '//cdn-go.cn/cdn/vod-js-sdk-v6/latest/vod-js-sdk-v6.js',
//   'webRTC-adapter': '//oss-web.duanshu.com/ds_projects/ds_app_tech/static/js/adapter.js'
// }

// export const loadJsPromise = (name) => {
//   if (!jsArray.hasOwnProperty(name)) {
//     return Promise.reject('找不到' + name)
//   }
//   const el = document.createElement('script')
//   el.src = jsArray[name]
//   document.body.appendChild(el)
//   const promise = new Promise((resolve, reject) => {
//     el.onload = () => {
//       console.log('onload')
//       return resolve()
//     }
//     el.onerror = (e) => {
//       console.log('onerror')
//       return reject(e)
//     }
//   })
//   return promise
// }

// export const loadImgPromise = (imgs) => {
//   let items = []
//   if (typeof imgs === 'string') {
//     items.push(imgs)
//   } else if (Array.isArray(imgs)) {
//     items = items.concat(imgs)
//   } else {
//     console.log(`不支持 ${typeof imgs} 类型`)
//     return
//   }
//   return new Promise((resolve, reject) => {
//     let loadedCount = 0
//     for (let i = 0; i < items.length; i++) {
//       let image = new window.Image()
//       image.src = items[i]
//       image.onload = function() {
//         loadedCount++
//         if (loadedCount === items.length) {
//           resolve(items[i])
//         }
//       }
//       image.onerror = function() {
//         reject(items[i])
//       }
//     }
//   })
// }

// export const getPcHost = (shopConfig) => {
//   if (shopConfig.shop_info.h5_host) {
//     let arr = shopConfig.shop_info.h5_base_uri.split('.');
//     arr[0] = arr[0] + '-pc';
//     return arr.join('.');
//   } else {
//     return '';
//   }
// }

// // ios端打开微信公众号链接，在微信浏览器需要做隐藏价格
// export function isHiddenPrice() {
//   let ua = window.navigator.userAgent.toLocaleLowerCase()
//   return mobileDevice() === 'iOS' && /micromessenger/gi.test(ua) && sessionStorage.get('frompublic')
// }

// export const formatPhone = (phone) => {
//   return phone.replace(/^(.{3})(.*)(.{4})$/, '$1 $2 $3')
// }

// export const getOSSPrefix = (filePath, type) => {
//   return type ? (OSSHost[type] + '/ds_projects/ds_app_tech/static/' + filePath) : (OSSHost['duanshu'] + '/ds_projects/ds_app_tech/static/' + filePath)
// }

// export const isString = (val) => {
//   return Object.prototype.toString.call(val) === "[object String]";
// }

// export const isArray = (val) => {
//   return Object.prototype.toString.call(val) === '[object Array]';
// }

// export const isObject = (val) => {
//   return Object.prototype.toString.call(val) === "[object Object]";
// }

// export const isBlank = (val) => {
//   if (Object.prototype.toString.call(val) === '[object Undefined]') { //空
//     return true
//   } else if (
//     Object.prototype.toString.call(val) === '[object String]' ||
//     Object.prototype.toString.call(val) === '[object Array]') { //字条串或数组
//     return val.length == 0 || val == 'undefined' ? true : false
//   } else if (Object.prototype.toString.call(val) === '[object Object]') {
//     return JSON.stringify(val) == '{}' ? true : false
//   } else if (Object.prototype.toString.call(val) === '[object Null]') {
//     return true
//   } else {
//     return false
//   }
// }

// // 颜色：十六进制转rgba
// export const getRgbaColorByColorStr = (hex, alpha = 1) => {
//   const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
//   return `rgba(${r},${g},${b},${alpha})`;
// }
