































import { Component, Vue, Watch, Prop,Mixins } from 'vue-property-decorator'
import qcloudUploadFile from './qcloud-upload-file-flow.vue';
  @Component({
    components: {
      qcloudUploadFile
    }
  })
export default class MyComponent extends Vue {
  // @Prop({ default: () => { return {} } }) label! :any
  @Prop() label! :Number
  @Prop({ default: () => { return 5.2 } }) maxFileSize! :Number
  @Prop({ default: () => { return -350 } }) progressLeft! :Number
  @Prop() disabled! :boolean
  images:any = []
  curImage:any = {}
  uploadList:any = []
  finish:any = false
  get progressStyle() {
    return {
      left: `${this.progressLeft}px`
    }
  }
  created() {
    this.initEmptyClick();
  }
  initEmptyClick() {
    let that = this;
    document.onclick = function(ev) {
      let event:any = ev || window.event;
      let targetId:any = event.target ? event.target.id : event.srcElement.id;
      if(that.finish) {
        that.images = [];
      }
    }
  }
  onFileSelect(files:any) {
    this.images = [];
    this.uploadList = [];
    this.finish = false;
  }
  onFileUpdate(index:any, file:any, size:any) {
    let uploadData = {
      'finish': false,
      'size': size,
      'name': file.name,
      'style': { 'width': 0 },
      'percent': '等待中',
      'url': '',
      'error': ''
    };
    this.curImage = uploadData
    this.images.push(uploadData);
  }
  onFileStart(index:any, file:any) {
    this.images[index].style.width = '0%';
    this.curImage.style.width = '0%';
    this.finish = false;
  }
  onFileProgress(index:any, file:any, percent:any) {
    if (this.images[index]) {
      this.images[index].percent = percent + '%';
      this.images[index].style.width = percent + '%';
      this.curImage.percent = percent + '%';
      this.curImage.style.width = percent + '%';
      this.curImage.finish = false;
    }
  }
  onFileError(index:any, file:any, error:any) {
    if (this.images[index]) {
      this.images[index].error = error;
      this.curImage.error = error;
    }
  }
  onFileFinish(index:any, file:any, data:any) {
    if (this.images[index]) {
      this.uploadList.push({
        info: {
          url: data.data.source_url,
          size: (file.size / 1024).toFixed(0),
          file_name: file.name
        }
      });
      this.images[index].percent = '已完成';
      this.images[index].style.width = '100%';
      this.images[index].finish = true;
      this.curImage.percent = '已完成';
      this.curImage.style.width = '100%';
      this.curImage.finish = true;
    }
  }
  urlData(data: any) {
    this.$emit('urlData', data)
  }
  reminder() {
    if(this.disabled) {
      this.$message('最多上传九张图片')
    }
  }
  // onAllFileFinish() {
  //   api.addMaterial({ type: 'image', sign: 'manage', tag_id: this.label, content: this.uploadList }).then(res => {
  //     for (let i = 0; i < this.images.length; i++) {
  //       if (!this.images[i].error) {
  //         this.$message.success('图片上传成功');
  //         break;
  //       }
  //     }
  //     this.finish = this.images.filter(item => !item.finish).length == 0;
  //     this.$emit('onFinish');
  //   }, rej => {
  //     this.$message.error(rej.message);
  //   });
  // }
}
