




















import { Component, Vue, Watch, Prop,Mixins } from 'vue-property-decorator'
import fromGroup from './form-group.vue'
// import * as utils from './filters/utils'
import TextMixins from './mixins'
  @Component({
    components: {
      fromGroup
    }
  })
export default class MyComponent extends Mixins(TextMixins) {
  // @Prop({ default: () => { return {} } }) iptData! :any
  DateValue : any = ''
  get pass() {
    return this.requireValid
  }
  inputHandler() {
    let dataTime:string
    dataTime = this.DateValue
    if(dataTime) {
      dataTime  = dataTime.toString().substring(16,21)
    }
    this.updateVal(dataTime)
  }
  mounted() {
  }
}
