












import { Component, Vue, Watch, Prop,Mixins } from 'vue-property-decorator'
import fromGroup from './form-group.vue'
import * as utils from './filters/utils'
import TextMixins from './mixins'
  @Component({
    components: {
      fromGroup
    }
  })
export default class MyComponent extends Mixins(TextMixins) {
  // @Prop({ default: () => { return {} } }) iptData! :any
  TextInput: any = ''
  get pass() {
    // 必填验证
    let validation = this.iptData.validation
    let flag = true
    if (!this.requireValid) {
      this.errMsg = '字符长度不可小于0，最多 ' + (validation.max_length / 2) + ' 个汉字，' + validation.max_length + ' 个字符，支持符号输入 '
      flag = false
    } else if (this.getStrBytes(this.val) > validation.max_length) {
      this.errMsg = '字符长度不可大于' + validation.max_length + '，最多 ' + (validation.max_length / 2) + ' 个汉字，' + validation.max_length + ' 个字符，支持符号输入 '
      flag = false
    } else if (this.val && this.iptData.component_slug === 'email' && !utils.isEmail(this.val)) {
      this.errMsg = '邮箱格式不正确'
      flag = false
    }
    return flag
  }
  getStrBytes(str:any) {
    if (str == null || str === undefined) return 0
    if (typeof str !== 'string') {
      return 0
    }
    let total = 0
    let charCode
    let i
    let len
    for (i = 0, len = str.length; i < len; i++) {
      charCode = str.charCodeAt(i)
      if (charCode <= 0x007f) {
        total += 0.5
      } else {
        total += 1
      }
    }
    return total
  }
  inputHandler() {
    // let ipt:any = this.TextInput
    this.updateVal(this.TextInput)
  }
  mounted() {
    
  }
}
