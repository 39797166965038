


















import { Component, Vue, Watch, Prop,Mixins } from 'vue-property-decorator'
import fromGroup from './form-group.vue'
// import * as utils from './filters/utils'
import TextMixins from './mixins'
  @Component({
    components: {
      fromGroup
    }
  })
export default class MyComponent extends Mixins(TextMixins) {
  // @Prop({ default: () => { return {} } }) iptData! :any
  SexValue : any = ''
  SexOptions:any = this.iptData.ext.options
  get pass() {
    return this.requireValid
  }
  inputHandler() {
    // let ipt:any = this.TextInput
    this.updateVal(this.SexValue)
  }
  mounted() {
  }
}
