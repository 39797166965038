import FormGroup from './form-group.vue'

import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
@Component({
  components: {
    FormGroup  
  }
})
export default class MyComponent extends Vue {
    @Prop({}) iptData !:any
    @Prop({}) val !:any
    @Prop({}) showErr !:any
    errMsg:any = ''
    updateVal(val:any) {  
      this.$emit('valChange', { key: this.iptData.key, val })
    }

    get requireValid() {
    // 必填验证
      let flag = true
      if (this.iptData.required && !this.val) {
        flag = false
        this.errMsg = this.iptData.name + ' 为必填项'
      }
      return flag
    }
}