export const getTime = () => {
  const myDate = new Date();
  const year = myDate.getFullYear();
  const mon = myDate.getMonth() + 1;
  const dd = myDate.getDate();
  const hh = myDate.getHours();
  const month = (mon < 10 ? '0' + mon : mon);
  const day = dd < 10 ? '0' + dd : dd;
  const hour = hh < 10 ? '0' + hh : hh;
  const string = '/' + year + '/' + month + '/' + day + '/' + hour;
  return string;
};
const qcloudSetting:any = {
  appid: '1253562005',
  bucket_name: 'duanshu',
  client_id: 'common',
  biz_type: 'content',
  dir_name: 'dsapply',
  test_name: 'test',
  district: 'sh',
  video: 'https://vod.qcloud.com/v2/index.php'
};
export const getQcloud = (type: any) => {
  if(type === 'dir_name' && envType()) {
    type = 'test_name';
  }
  return qcloudSetting[type] || ''
};
export const envType = function() {
  const topHost = window.location.host.split('.')[0]
  if (topHost.indexOf('pre') != -1) {
    return 'pre'
  } else if (topHost.indexOf('test') != -1) {
    return 'test'
  }
  const secHost = window.location.href.split('.')[1]
  if (secHost.indexOf('pre') != -1) {
    return 'pre'
  } else if (secHost.indexOf('test') != -1) {
    return 'test'
  }
  return 'rel'
};
export const randomNum = (len:any) => {
  var salt = '';
  for(var i = 0; i < len; i++) {
    var tmp = parseInt(Math.floor(Math.random() * 10).toString());
    if(!tmp) {
      tmp = 2;
    }
    salt += tmp;
  }
  return salt;
};