


















import { Component, Vue, Watch, Prop,Mixins } from 'vue-property-decorator'
import fromGroup from './form-group.vue'
// import * as utils from './filters/utils'
import TextMixins from './mixins'
  @Component({
    components: {
      fromGroup
    }
  })
export default class MyComponent extends Mixins(TextMixins) {
  // @Prop({ default: () => { return {} } }) iptData! :any
  DateValue : any = ''
  get pass() {
    return this.requireValid
  }
  inputHandler() {
    this.updateVal(this.DateValue)
  }
  mounted() {
  }
}
