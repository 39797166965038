












import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import mixins from './mixins'
import {getCourseMaterialsList} from '@/modules/course/api'

  @Component({
  })
export default class MaterialList extends Mixins(mixins) {
    list: Array<any> = []
    page: any = {}

    created() {
      this.initData()
    }
    get filterList() {
      return this.list.filter(v => {
        return !v.bind || !v.bind.class
      })
    }
    initData() {
      return getCourseMaterialsList(this.courseDetail.content_id).then(e => {
        this.list = e.data
        this.page = e.page
        this.$emit('materialCountChange', this.filterList.length)
      })
    }
    toDetail(v: any) {
      if (this.courseDetail.is_subscribe) {
        let query: any = {}
        let {token} = this.$route.query
        if (token) {
          query.token = token
        }
        this.$router.push({
          name: 'courseMaterial',
          params: {
            courseId: this.courseDetail.content_id,
            mId: v.id.toString()
          },
          query
        })
      } else {
        this.$message('加入课程后才能看')
      }
    }
}
