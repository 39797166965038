






import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import UeditorDisplay from '@/modules/common/components/ueditor-display/index.vue'
import mixins from './mixins'

  @Component({
    components: {
      UeditorDisplay
    }
  })
export default class DetailInfo extends Mixins(mixins) {
}
