

















import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import mixins from './mixins'

  @Component({
  })
export default class Teachers extends Mixins(mixins) {
}
