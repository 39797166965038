
















import { Component, Vue, Watch, Prop,Mixins } from 'vue-property-decorator'
import fromGroup from './form-group.vue'
import * as utils from './filters/utils'
import options from './filters/data'
import TextMixins from './mixins'
import 'element-ui/lib/theme-chalk/index.css';
interface arr{
  value: string,
  label: string,
  children: any,
  area:string
}

@Component({
  components: {
    fromGroup,
    // EluiChinaAreaDht
  }
})
export default class MyComponent extends Mixins(TextMixins) {
  // @Prop({ default: () => { return {} } }) iptData! :any
  location: any = ''
  options:any = options.options
  // areaSelectData: any = regionData
  get optionData() {
    let sheng = this.options[86]
    let data: Array<arr> = []
    for (let i in sheng) {
      data.push({value: sheng[i], label: sheng[i],area: i, children: []})
    }
    data.forEach(r => {
      let arr: Array<arr> = []
      for (let i in this.options[r.area]) {
        arr.push({value: this.options[r.area][i], label: this.options[r.area][i],area: i, children: []})
        arr.forEach(r => {
          let arr1 = []
          for (let j in this.options[r.area]) {
            arr1.push({value: this.options[r.area][j],area: j,label: this.options[r.area][j]})
          }
          r.children = arr1
        })
      }
      r.children = arr
    })
    return data
  } 
  get pass() {
    // 必填验证
    let validation = this.iptData.validation
    let flag = true
    if (!this.requireValid) {
      this.errMsg = '字符长度不可小于0，最多 ' + (validation.max_length / 2) + ' 个汉字，' + validation.max_length + ' 个字符，支持符号输入 '
      flag = false
    } else if (this.getStrBytes(this.val) > validation.max_length) {
      this.errMsg = '字符长度不可大于' + validation.max_length + '，最多 ' + (validation.max_length / 2) + ' 个汉字，' + validation.max_length + ' 个字符，支持符号输入 '
      flag = false
    } else if (this.val && this.iptData.component_slug === 'email' && !utils.isEmail(this.val)) {
      this.errMsg = '邮箱格式不正确'
      flag = false
    }
    return flag
  }
  inputHandler() {
    // let ipt:any = this.TextInput
    this.updateVal(this.location.join(' '))
  }
  getStrBytes(str:any) {
    if (str == null || str === undefined) return 0
    if (typeof str !== 'string') {
      return 0
    }
    let total = 0
    let charCode
    let i
    let len
    for (i = 0, len = str.length; i < len; i++) {
      charCode = str.charCodeAt(i)
      if (charCode <= 0x007f) {
        total += 0.5
      } else {
        total += 1
      }
    }
    return total
  }
  mounted() {
    
  }
}
