
import { phpApi, pyApi } from '@/api/request'
import * as model from '../models'
import * as common from '@/modules/common/models/index'


export function getCosSignature() {
  return pyApi({
    method: 'Get',
    url: `/h5/cos/signature`,
    params: {
      source: 'qcloud'
    }
  })
}

// 获取client-视频上传签名
// http://yapi.hogecloud.com/project/11/interface/api/8652
export function getQcloudVodSignature() {
  return pyApi({
    method: 'POST',
    url: `/fairy/api/v1/videos/qcloud_vod/signature/`,
    params: {
      
    }
  })
}
