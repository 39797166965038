<template>
  <class-wrap
    :class-data="classData"
    :unlock-data="unlockData"
    :content-info="contentInfo"
  >
    <template slot="ico">&#xe74f;</template>
    测验<span class="i">&nbsp;|&nbsp;{{content.item_count}}道题</span>
  </class-wrap>
</template>
<script>
import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import ClassWrap from './class-wrap.vue'
import ClassMixins from '@/modules/course/components/class-list/class-mixins'

  @Component({
    components: {
      ClassWrap
    }
  })
export default class ExamClassItem extends Mixins(ClassMixins) {
}
</script>
<style lang="scss" scoped rel="stylesheet/scss">
</style>
