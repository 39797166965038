














































import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import CourseDetailMixins from './mixins'

@Component({
})
export default class CourseBefore extends Mixins(CourseDetailMixins) {
  mounted() {
    // tab 栏试学标签
    if (this.courseDetail.support_services.indexOf('try_learn') != -1) {
      let newTab = {
        ...this.tabList[1],
        tag: '试学'
      }
      this.tabList.splice(1, 1, newTab)
    }
  }
  get showServerList() {
    return this.serverList.length > 0
  }
  get serverList() {
    let list = [].concat(this.courseDetail.support_services)
    let mapData = {
      try_learn: {
        m: '免费试学',
        s: '可通过试学课时免费体验课程内容'
      // },
      // community: {
      //   m: '交流互动',
      //   s: '专属课程的兴趣组，学员互动交流区，老师在线答疑'
      // },
      // daka: {
      //   m: '随课打卡',
      //   s: '专属课程的打卡，在线督学，提升学习效率'
      // },
      // material: {
      //   m: '课件资料',
      //   s: '随课教辅资料，全面配套讲解，随时在线观看'
      }
    }
    return list.map(e => {
      return mapData[e]
    }).filter(e => !!e)
  }
}
