














import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
  @Component({
    components: {
      
    }
  })
export default class MyComponent extends Vue {
  @Prop({}) iptData !:any
  @Prop({}) arrow !:any
  @Prop({ default: true }) pass !:Boolean
  @Prop({ default: true }) isFlex !:Boolean
  @Prop({}) errMsg !:any
  @Prop({ default: false }) showErr !:Boolean
  created() {
  }
  activated() {
  }
}
