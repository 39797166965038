<template>
  <div class="class-wrap" @click="toDetail">
    <div class="title">
      <span class="label" v-if="isShowTest">试学</span>{{classData.title}}
    </div>
    <div class="desc">
      <span class="label green" v-if="classData.materials && classData.materials.length > 0">课件</span>
      <slot></slot><span class="i" v-if="shopShowData.view && !contentInfo.is_subscribe">&nbsp;|&nbsp;{{classData.view_count | numberFormat}}次学习</span><span class="leared" v-show="learedObj.show">&nbsp;|&nbsp;<ds-icon class="ico" code="&#xe699;" v-show="learedObj.ico"></ds-icon>{{learedObj.text}}</span><ds-icon class="lock-ico" code="&#xe6b2;" v-show="locked"></ds-icon>
    </div>
    <div>
      <slot name="extInfo"></slot>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import ClassWrapMixins from '@/modules/course/components/class-list/class-wrap-mixins'

  @Component({
  })
export default class ClassWrap extends Mixins(ClassWrapMixins) {
}
</script>
<style lang="scss" scoped rel="stylesheet/scss">
  @import '@/style/theme.scss';
  .class-wrap{
    position:relative;
    padding:16px 20px;
    cursor:pointer;
    transition: all .3s;
    .title{
      font-size:16px;
      line-height:21px;
      color:#333;
      .label{
        height:22px;
        line-height:22px;
        background:$color-theme;
        border-radius:4px;
        font-size:12px;
        color:#fff;
        padding:0 6px;
        vertical-align:middle;
        display:inline-block;
        margin-right:9px;
        position:relative;
        top:-1px;
      }
    }
    .desc{
      margin-top:10px;
      font-size:13px;
      line-height:1em;
      color:#999;
      .leared{
        font-size:11px;
        margin-top:4px;
        .ico{
          margin-right:5px;
          transform:scale(.7);
          display:inline-block;
          vertical-align:middle;
        }
      }
      .lock-ico{
        color:#cccccc;
        margin: 0 5px;
      }
      .label{
        height:22px;
        line-height:22px;
        background:#e9eff4;
        border-radius:4px;
        font-size:12px;
        color:#999999;
        padding:0 6px;
        vertical-align:middle;
        display:inline-block;
        margin-right:9px;
        position:relative;
        top:-1px;
      }
    }
    .ext-info,
    .desc,
    .title{
      transition:all .3s;
    }
    &:hover{
      .ext-info,
      .desc,
      .title{
        color:$color-theme !important
      }
    }
  }
</style>
