<template>
  <class-wrap
    :class-data="classData"
    :unlock-data="unlockData"
    :content-info="contentInfo"
  >
    <template slot="ico">&#xe742;</template>
    图文<span class="i" v-if="classData.letter_count">&nbsp;|&nbsp;{{classData.letter_count | numberFormat}}字</span>
  </class-wrap>
</template>
<script>
import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import ClassWrap from './class-wrap.vue'
import ClassMixins from '@/modules/course/components/class-list/class-mixins'

  @Component({
    components: {
      ClassWrap
    }
  })
export default class ArticleClassItem extends Mixins(ClassMixins) {
}
</script>
<style lang="scss" scoped rel="stylesheet/scss">
</style>
