<template>
  <div class="charpter-wrap">
    <div class="hd" @click="toggle" :class="{none: !showCtn || data.class_content.length < 1, active: processingPrecent === 100}">
      <span class="title">
        {{data._index | zeroFormat}}.{{data.title}}
      </span>
      <span class="label-group" v-show="showCtn">
        <span v-if="processingPrecent === 100"><ds-icon class="h5tech" code="&#xe699;"></ds-icon>已学完</span>
        <span v-else-if="contentInfo.is_subscribe">已学{{processingPrecent}}%</span>
      </span>
      <ds-icon :code="showCtn ? '&#xe6ab;' : '&#xe69b;'" class="arr-ico"></ds-icon>
    </div>
    <div class="bd" v-show="showCtn">
      <template v-for="(v, i) in data.class_content">
        <component
          v-if="classTypeComponent(v.content_type)"
          :is='classTypeComponent(v.content_type)'
          :class-data="v"
          :unlock-data="unlockData"
          :content-info="contentInfo"
          :key='i'
        ></component>
      </template>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import ItemArticle from './article'
import ItemAudio from './audio'
import ItemVideo from './video'
import ItemLive from './live'
import ItemExam from './exam'
import mixins from '@/modules/course/components/class-list/charpter-mixins'

  @Component({
    components: {
      ItemArticle,
      ItemAudio,
      ItemVideo,
      ItemLive,
      ItemExam
    }
  })
export default class CharpterWrap extends Mixins(mixins) {
}
</script>
<style lang="scss" scoped rel="stylesheet/scss">
  .charpter-wrap{
    margin-bottom:10px;
  }
  .hd{
    padding:20px;
    position:relative;
    background:#fafafa;
    border:1px solid #F5F5F5;
    user-select:none;
    .title{
      font-size:16px;
      font-weight:bold;
      line-height:1em;
    }
    .label-group{
      color:#999;
      font-size:14px;
      line-height:16px;
      margin-left:5px;
      .h5tech{
        display:inline-block;
        transform:scale(.7);
        margin-right:5px;
      }
    }
    .arr-ico {
      position: absolute;
      top: 50%;
      right:20px;
      transform: translateY(-50%) scale(.7);
      color:#CCCCCC;
    }
  }
</style>
