


















import { Component, Vue, Watch, Prop,Mixins } from 'vue-property-decorator'
import fromGroup from './form-group.vue'
import * as utils from './filters/utils'
import TextMixins from './mixins'
  @Component({
    components: {
      fromGroup
    }
  })
export default class MyComponent extends Mixins(TextMixins) {
  // @Prop({ default: () => { return {} } }) iptData! :any
  SexValue : any = ''
  SexOptions:Array<any>= [{value: '男',label: '男'}, {value: '女',label: '女'}]
  get pass() {
    return this.requireValid
  }
  getStrBytes(str:any) {
    if (str == null || str === undefined) return 0
    if (typeof str !== 'string') {
      return 0
    }
    let total = 0
    let charCode
    let i
    let len
    for (i = 0, len = str.length; i < len; i++) {
      charCode = str.charCodeAt(i)
      if (charCode <= 0x007f) {
        total += 0.5
      } else {
        total += 1
      }
    }
    return total
  }
  inputHandler() {
    // let ipt:any = this.TextInput
    this.updateVal(this.SexValue)
  }
  mounted() {
    
  }
}
