















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import iptText from './components/ipt-text.vue'
import iptTextarea from './components/ipt-textarea.vue'
import iptArea from './components/ipt-area.vue'
import iptNum from './components/ipt-num.vue'
import iptSelect from './components/ipt-select.vue'
import iptDate from './components/ipt-date.vue'
import iptDatetime from './components/ipt-datatime.vue'
import iptTime from './components/ipt-time.vue'
import iptError from './components/ipt-error.vue'
import iptMultiple from './components/ipt-multiple.vue'
import iptImage from './components/ipt-image.vue'
import iptSingle from './components/ipt-single.vue'
import { getBindingForm } from '@/modules/course/api/index'

@Component({
  components: {
    iptText,
    iptArea,
    iptNum,
    iptSelect,
    iptError,
    iptTextarea,
    iptDate,
    iptMultiple,
    iptImage,
    iptSingle,
    iptDatetime,
    iptTime
  },
  filters: {
    typeMap: (val : any) => {
      let map :any  = {
        'text': 'text',
        'textarea': 'textarea',
        'number': 'num',
        'select': 'select',
        'date': 'date',
        'datetime': 'datetime',
        'time': 'time',
        'area': 'area',
        'multiple': 'multiple',
        'single': 'single',
        'images': 'image'
      }
      let type = map[val] || 'error'
      
      return 'ipt-' + type
    },
    stringIt(val:any) {
      return JSON.stringify(val)
    }
  },
})
export default class CategoryContentList extends Vue {
  @Prop({ default: () => { return {} } }) courseDetail!: any
    errorObj:any = null
    config: Array<any> = []
    formVal: any = {}
    showErr: boolean = false
    info: any 
    get themeColor() {
      return this.$store.getters.themeColor.color1
    }
    mounted () {
      this.getFormConfig()
    }
    activated() {
      this.formVal = {}
    }
    valHandle({key, val} : any) {  
      this.$set(this.formVal, key, val)
    }
    check() {
      let ipts:any = this.$refs.ipts || []
      let pass:boolean = true

      this.showErr = false
      for (let i = 0; i < ipts.length; i++) {
        let ipt = ipts[i]
        if (!ipt.pass) {
          pass = false
          this.showErr = true
          break
        }
      }
      
      return pass
    }
    getFormConfig() {
      let type = this.courseDetail.type
      let id = this.$route.params.id
      getBindingForm({
        content_type: type,
        content_id: id
      }).then(res => {
        this.info = res
        // this.$emit('info',this.info)
        this.config = res.fields
      }).catch(({errorObj}) => {
        this.errorObj = errorObj
      })
    }
}
