









































import { Component, Vue, Prop, Mixins, Watch } from 'vue-property-decorator'
import Charpter from './components/charpter-wrap.vue'
import mixins from '../mixins'
import ClassListIndexMixins from '@/modules/course/components/class-list/index-mixins'

  @Component({
    components: {
      Charpter
    }
  })
export default class ClassList extends Mixins(mixins, ClassListIndexMixins) {
  slotVisiable: boolean = false

  @Prop({default: 0}) materialCount: any
}
