<template>
  <class-wrap
    :class-data="classData"
    :unlock-data="unlockData"
    :content-info="contentInfo"
  >
    <template slot="ico">&#xe74c;</template>
    直播<span class="i" v-if="livePerson">&nbsp;|&nbsp;{{livePerson}}老师</span>
    <template slot="extInfo">
      <div class="ext-info">
        <span>{{content.start_timestamp | formatDate}}开播</span>
        <span class="label" :class="liveStatus.cls">{{liveStatus.text}}</span>
      </div>
    </template>
  </class-wrap>
</template>
<script>
import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
import ClassWrap from './class-wrap.vue'
import ClassMixins from '@/modules/course/components/class-list/class-mixins'

  @Component({
    components: {
      ClassWrap
    }
  })
export default class LiveClassItem extends Mixins(ClassMixins) {
  get livePerson() {
    let person = this.content.live_person || {}
    if (person && person.length > 0) {
      person = person[0]
    }
    return person.nick_name || person.name
  }
  get liveStatus() {
    // 预告
    let statusObj = {
      text: '预告',
      cls: 'before'
    }
    let state = this.content.live_state
    switch (state) {
      case 0 :
        break
      case 1 :
        // 直播中
        statusObj = {
          text: '直播中',
          cls: 'live-ing'
        }
        break
      case 2 :
        // 回放
        statusObj = {
          text: '已结束',
          cls: 'after'
        }
        break
    }
    return statusObj
  }
}
</script>
<style lang="scss" scoped rel="stylesheet/scss">
  .ext-info{
    font-size:11px;
    color:#999;
    line-height:1em;
    margin-top:10px;
    .label{
      padding-left:10px;
      position:relative;
      &:before {
        content: '';
        width: 3px;
        height: 3px;
        border-radius: 50%;
        display: block;
        position: absolute;
        top: 50%;
        left: 3px;
        transform: translateY(-50%);
      }
      &.before{
        color:#F8AA5E;
        &:before{
          background:#F8AA5E;
        }
      }
      &.live-ing{
        color:#1caf6a;
        &:before{
          background:#1caf6a;
        }
      }
      &.after{
        color:#999999;
        &:before{
          background:#999999;
        }
      }
    }
  }
</style>
