



















import { Component, Vue, Watch, Prop,Mixins } from 'vue-property-decorator'
import fromGroup from './form-group.vue'
import imagePicker from './images/imagePicker.vue'
// import * as utils from './filters/utils'
import TextMixins from './mixins'
  @Component({
    components: {
      fromGroup,
      imagePicker
    }
  })
export default class MyComponent extends Mixins(TextMixins) {
  imagesUrl : any = []
  dialogImageUrl: any = ''
  dialogVisible:boolean = false
  disabled:boolean = false
  get pass() {
    // 必填验证
    // let validation = this.iptData.validation
    return this.requireValid
  }
  // updateImg(data)) {
  //   this.images = data
  //   this.updateVal(this.images)
  // }
  handleRemove(file:any, fileList:any) {
    console.log(file, fileList);
  }
  handlePictureCardPreview(file:any) {
    this.dialogImageUrl = file.url;
    this.dialogVisible = true;
  }
  urlData(data: any) {
    // this.imagesUrl = []
    this.imagesUrl.push(data.data.access_url)
    this.imagesUrl = this.unique(this.imagesUrl)
    // this.images = data
    this.updateVal(this.imagesUrl)
    if(this.imagesUrl.length >= 9) {
      this.disabled = true
      this.$message('最多上传九张图片')
    }
  }
  unique (arr:any) {
    return Array.from(new Set(arr))
  }
  mounted() {
  }
}
